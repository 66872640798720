import styled from 'styled-components'

export const MicropaymentGraph = styled.div`
  margin: 30px auto;
  display: flex;
  height: 120px;
  width: 250px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #f7f9fc;
`

export const SignicatLogoWrap = styled.div`
  padding: 16px;
`

export const Paragraph = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
`
