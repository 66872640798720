import { ContentContainer } from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '../../components/App'
import { MediaPermissionsChecker } from '../../components/MediaPermissionsChecker/MediaPermissionsChecker'
import { VideoIdAfterCancelPopup } from '../../components/VideoIdAfterCancelPopup/VideoIdAfterCancelPopup'
import {
  EID_ERROR_CODES_RETRY,
  PostStartVideoResponse,
} from '../../constants/videoId'
import { actionReport } from '../../methods/actionReport'
import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'
import {
  forcePathname,
  forceRedirect,
  forceReload,
} from '../../methods/forceRedirect'
import { handleActionButtonsInject } from '../../methods/injectHTMLVideoID'
import { HideVideoIdModal, VideoId } from '../../styles/eid.styles'
import { CANCEL_SURVEY_VIDEO_ID } from '../../constants/cancelSurvey'
import { buttonsHandler } from '@/methods/keyboardAccessibility'
import { handlePhonePrefix } from '../../methods/videoIdPhoneHandler'

let videoId = null
const apiUrl = process.env.WEB_API_URL

export const VideoIdIkanoPage = observer(() => {
  const store = useContext(StoreContext)

  const { pageWidth, userAgent } = store.AppState
  const { theme } = store.InterfaceState
  const { currentScenarioId, context } = store.ScenarioState

  const { browser } = userAgent
  const { language } = store.TranslationsState
  const { VideoIdPage: trans } = store.TranslationsState.translations
  const cancelSurveyScreenEnabledServiceList =
    context?.cancelSurveyScreenEnabledServiceList || []

  const [isMobile, setIsMobile] = useState(false)
  const [phoneHandled, setPhoneHandled] = useState(false)
  const [overlayHandled, setOverlayHandled] = useState(false)
  const [phone, setPhone] = useState('')
  const [eidError, setEidError] = useState('')
  const [afterClosePopupVisible, setAfterClosePopupVisible] = useState(false)
  const [isPermissionCheckSuccessful, setIsPermissionCheckSuccessful] =
    useState(false)

  async function handleContext() {
    try {
      const res = await axiosInstance.get(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/get-context`,
        {
          withCredentials: true,
        }
      )

      localStorage.setItem(
        'show-waiting-screen',
        res.data.showTransactionCompletedWaitingScreen
      )
    } catch (e) {
      devLog(e)
    }
  }

  async function handleAuth() {
    try {
      const { data } = await axiosInstance.post<PostStartVideoResponse>(
        `${apiUrl}/electronic-id/start-video`,
        {},
        {
          withCredentials: true,
        }
      )

      await getPhone()
      await handleContext()

      mountEid(data)
    } catch (e) {
      devLog(e)
    }
  }

  async function callComplete() {
    try {
      await axiosInstance.post(
        `${apiUrl}/electronic-id/complete`,
        {},
        {
          withCredentials: true,
        }
      )
    } catch (e) {
      devLog(e)
    }
  }

  const mountEid = (startVideoRes: PostStartVideoResponse): void => {
    const {
      electronicIdAuthorization: authToken,
      electronicIdDocType: docType,
      isBiometricConsentAccepted: eIDBiometricConsent,
    } = startVideoRes

    if (authToken === '') {
      devLog('missing videoid auth')
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eid = (window as any).EID
    videoId = eid.videoId('#video', {
      lang: language,
    })

    videoId.start({
      authorization: authToken,
      idType: docType,
      eIDBiometricConsent,
    })
    videoId.on('completed', () => {
      videoId.turnOff()
      callComplete()
      forceRedirect(
        `${apiUrl}/electronic-id/redirect-from-video?result=complete`
      )
    })
    videoId.on('failed', function (error) {
      if (error) {
        const errorCode = error.error?.error || 'Unknown.Error'

        setEidError(errorCode)

        if (EID_ERROR_CODES_RETRY.includes(errorCode)) {
          return
        }

        forceRedirect(
          `${apiUrl}/electronic-id/redirect-from-video?result=fail&error=${errorCode}`
        )
      }
    })
    videoId.on('notification', async function (event) {
      await axiosInstance.post(
        `${apiUrl}/electronic-id/store-sdk-notification`,
        event,
        {
          withCredentials: true,
        }
      )
    })
  }

  async function getPhone() {
    try {
      const res = await axiosInstance.get(
        `${apiUrl}/electronic-id/get-phone-info`,
        {
          withCredentials: true,
        }
      )

      const { phoneNumber } = res.data
      setPhone(phoneNumber)
    } catch (e) {
      devLog(e)
    }
  }

  const handleButtons = () => {
    buttonsHandler()
  }

  const handleOverlay = () => {
    setOverlayHandled(true)

    const element = document.getElementsByClassName(
      'eid-overlay'
    )[0] as HTMLInputElement

    element.setAttribute('id', 'overlay')
  }

  const handlePhoneNumber = () => {
    setPhoneHandled(true)

    const element = document.getElementsByClassName(
      'eid-textbox'
    )[0] as HTMLInputElement

    element.value = handlePhonePrefix(phone)

    element.focus()
    element.dispatchEvent(
      new Event('input', { bubbles: true, cancelable: true })
    )

    element.addEventListener('paste', function (event) {
      event.preventDefault()

      let pastedData = event.clipboardData.getData('text')

      if (pastedData.charAt(0) === '0' || pastedData.charAt(0) === '+') {
        pastedData = handlePhonePrefix(pastedData.replace(/^[0+]+/, '+'))
      }

      element.value = pastedData

      element.focus()
      element.dispatchEvent(
        new Event('input', { bubbles: true, cancelable: true })
      )
    })

    element.addEventListener('keydown', function (event) {
      if (
        event.key === '-' ||
        event.key === '+' ||
        event.key === 'E' ||
        event.key === 'e'
      ) {
        event.preventDefault()
      }

      if (event.key === '0' && this.value.length === 0) {
        event.preventDefault()
      }
    })
  }

  useInterval(
    () => {
      if (document.getElementsByClassName('eid-a').length) {
        const changeItButton = document.getElementsByClassName('eid-a')[0]
        changeItButton.addEventListener('click', changePhoneButtonListener)
      }
    },
    phoneHandled ? 300 : null
  )

  useInterval(
    () => {
      document.getElementsByClassName('eid-textbox').length &&
        handlePhoneNumber()
    },
    !phoneHandled ? 300 : null
  )

  useInterval(
    () => {
      document.getElementsByClassName('eid-overlay').length && handleOverlay()
    },
    !overlayHandled ? 300 : null
  )

  useInterval(() => {
    document.getElementsByClassName('eid-overlay').length && handleButtons()
  }, 300)

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setIsMobile(true)
    }
  }, [pageWidth])

  useEffect(() => {
    if (!eidError) return

    if (EID_ERROR_CODES_RETRY.includes(eidError)) {
      const closeOnClick = () => {
        actionReport({
          type: 'event.onboarding-web.eidv.USER_CLOSED_MANUALLY',
          payload: {},
        })
        if (
          cancelSurveyScreenEnabledServiceList.some((cs) =>
            CANCEL_SURVEY_VIDEO_ID.includes(cs)
          )
        ) {
          setAfterClosePopupVisible(true)
        } else {
          forceRedirect(
            `${apiUrl}/electronic-id/redirect-from-video?result=fail&error=${eidError}`
          )
        }
      }

      const retryOnClick = () => {
        actionReport({
          type: 'event.onboarding-web.eidv.USER_RESTARTED_MANUALLY',
          payload: {},
        })
        if (
          currentScenarioId !== 'videoIdRetryIkano' &&
          currentScenarioId !== 'video-id-retry-ikano' &&
          currentScenarioId !== 'video-id-retry/ikano.eu'
        ) {
          forcePathname('/video-id-retry-ikano')
        } else {
          forceReload()
        }
      }

      handleActionButtonsInject(trans, closeOnClick, retryOnClick)
    }

    setEidError('')
  }, [eidError])

  useEffect(() => {
    if (isPermissionCheckSuccessful) {
      handleAuth()
    }
  }, [isPermissionCheckSuccessful])

  const changePhoneButtonListener = () => {
    actionReport({
      type: 'event.onboarding-web.eidv.I_DID_NOT_RECEIVE_SMS_CLICKED',
      payload: {},
    })
    setPhoneHandled(false)
  }

  const changePhoneHandledListener = () => {
    setPhoneHandled(false)
  }

  const handleClosePopup = useCallback(() => {
    forceRedirect(
      `${apiUrl}/electronic-id/redirect-from-video?result=fail&error=${eidError}`
    )
  }, [eidError])

  useEffect(() => {
    window.addEventListener('online', changePhoneHandledListener)

    return () => {
      document.removeEventListener('click', changePhoneButtonListener)
      window.removeEventListener('online', changePhoneHandledListener)
    }
  }, [])

  const renderVideoId = () =>
    isPermissionCheckSuccessful ? (
      <>
        {browser === 'firefox' ? <HideVideoIdModal /> : <></>}
        <VideoId
          {...theme.button}
          fontFamily={theme.globals.fontFamily}
          fontFamilyHeadline={theme.globals.fontFamilyHeadline}
          videoIdPhoneChangeButton={theme.videoIdPhoneChangeButton}
          videoIdCallToActionButton={theme.videoIdCallToActionButton}
          otpInput={theme.otpInput}
          focusVisible={theme.globals.focusVisible}
        >
          <div id="video" />
        </VideoId>
      </>
    ) : (
      <MediaPermissionsChecker
        onPermissionCheckEnd={setIsPermissionCheckSuccessful}
        redirectPath="/video-id-retry-ikano"
        redirectScenario="videoIdRetryIkano"
      />
    )

  return isMobile ? (
    <>
      <VideoIdAfterCancelPopup
        title={trans.feedbackPopupTitle}
        thankYouForYourFeedback={trans.thankYouForYourFeedback}
        sendButtonText={trans.send}
        cancelButtonText={trans.close}
        visibility={afterClosePopupVisible}
        handleVisibility={handleClosePopup}
        theme={theme}
      />
      {renderVideoId()}
    </>
  ) : (
    <ContentContainer
      {...theme.container}
      width="1000px"
      paddingMobile="0"
      marginMobile="0"
    >
      <VideoIdAfterCancelPopup
        title={trans.feedbackPopupTitle}
        thankYouForYourFeedback={trans.thankYouForYourFeedback}
        sendButtonText={trans.send}
        cancelButtonText={trans.close}
        visibility={afterClosePopupVisible}
        handleVisibility={handleClosePopup}
        theme={theme}
      />
      {renderVideoId()}
    </ContentContainer>
  )
})
