import styled from 'styled-components'

interface ContainerProps {
  display?: string
  fontFamily?: string
  fontFamilyHeadline?: string
  linkColor?: string
  linkColorHover?: string
  checkboxColor?: string
  checkboxColorActive?: string
  bgColor?: string
  input?: {
    bgColor?: string
    padding?: string
    borderRadius?: string
    border?: string
    boxShadow?: string
    margin?: string
    color?: string
    colorFocus?: string
    bgColorFocus?: string
    borderFocus?: string
    bgColorDisabled?: string
    borderDisabled?: string
    colorDisabled?: string
    fontSize?: string
    fontWeight?: string
    outline?: string
  }
  button?: {
    bgColor?: string
    padding?: string
    paddingMobile?: string
    borderRadius?: string
    minWidth?: string
    width?: string
    widthMobile?: string
    border?: string
    boxShadow?: string
    margin?: string
    color?: string
    bgColorHover?: string
    borderHover?: string
    colorHover?: string
    bgColorDisabled?: string
    borderDisabled?: string
    colorDisabled?: string
    fontSize?: string
    fontWeight?: string
  }
  backButton?: {
    borderColor?: string
    color?: string
    colorHover?: string
    disabled?: string
  }
  focusVisible: {
    outline: string
    boxShadow: string
    transition: string
  }
}

export const Container = styled.div.attrs({
  className: 'authflow-Xs2a-wrap-container',
})<ContainerProps>`
  width: 100%;
  border: none;
  padding: 0;
  display: ${(props) => (props.display ? `${props.display}` : 'visible')};

  .xs2a-dialog {
    @media (min-width: 900px) {
      width: 500px !important;
      margin: 24% auto 0px !important;
    }
  }

  .xs2a-abort-line {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  > div {
    width: 100%;

    button,
    input,
    select,
    a {
      transition: all 0.2s ease-in-out;

      &:focus-visible {
        outline: ${(props) => props.focusVisible.outline} !important;
        box-shadow: ${(props) => props.focusVisible.boxShadow} !important;
        transition: ${(props) => props.focusVisible.transition};
      }
    }

    h3 {
      font-family: ${(props) =>
        props.fontFamilyHeadline
          ? `${props.fontFamilyHeadline} !important`
          : ''};
      font-size: 1.8em !important;
      margin: 15px 0 !important;
      text-align: center !important;
      width: 100% !important;
      color: black !important;
    }

    .xs2a-paragraph p {
      line-height: 20px !important;
      margin: 10px 0 10px 0 !important;
      text-align: center !important;
    }

    .xs2a-description {
      color: rgb(80, 80, 80) !important;
      margin: 8px 0 30px 0 !important;
    }

    .xs2a-form-line {
      padding: 0px !important;

      label {
        color: rgb(80, 80, 80) !important;
        margin: 30px 0 8px !important;
        font-weight: 400 !important;
        font-size: 16px !important;

        a {
          color: ${(props) => (props.linkColor ? props.linkColor : 'gray')};

          &:hover {
            color: ${(props) =>
              props.linkColorHover ? props.linkColorHover : 'gray'};
          }
        }
      }

      select {
        background-color: ${(props) =>
          props.input.bgColor ? props.input.bgColor : 'white'};
        padding: ${(props) =>
          props.input.padding ? props.input.padding : '14px'};
        border-radius: ${(props) =>
          props.input.borderRadius ? props.input.borderRadius : '8px'};
        border: ${(props) =>
          props.input.border ? props.input.border : '1px solid silver'};
        box-shadow: ${(props) =>
          props.input.border ? props.input.border : 'none'};
        margin: ${(props) => (props.input.margin ? props.input.margin : '0')};
        color: ${(props) => (props.input.color ? props.input.color : 'gray')};
        outline: none !important;
        font-size: ${(props) =>
          props.input.fontSize ? props.input.fontSize : '14px'};
        font-weight: ${(props) =>
          props.input.fontWeight ? props.input.fontWeight : '400'};
        /* arrow */
        appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1.6em;

        &:focus-visible {
          color: ${(props) =>
            props.input.colorFocus ? props.input.colorFocus : 'black'};
          background-color: ${(props) =>
            props.input.bgColorFocus ? props.input.bgColorFocus : 'white'};
          border: ${(props) =>
            props.input.borderFocus
              ? props.input.borderFocus
              : '1px solid silver'};
          outline: ${(props) => props.focusVisible.outline} !important;
          box-shadow: ${(props) => props.focusVisible.boxShadow} !important;
          transition: ${(props) => props.focusVisible.transition};
        }

        &:disabled,
        &[disabled] {
          color: ${(props) =>
            props.input.colorDisabled ? props.input.colorDisabled : 'silver'};
          background-color: ${(props) =>
            props.input.bgColorDisabled ? props.input.bgColorDisabled : 'gray'};
          border: ${(props) =>
            props.input.borderDisabled
              ? props.input.borderDisabled
              : '1px solid silver'};
        }
      }

      input[type='text'],
      input[type='password'] {
        background-color: ${(props) =>
          props.input.bgColor ? props.input.bgColor : 'white'};
        padding: ${(props) =>
          props.input.padding ? props.input.padding : '14px'};
        border-radius: ${(props) =>
          props.input.borderRadius ? props.input.borderRadius : '8px'};
        border: ${(props) =>
          props.input.border ? props.input.border : '1px solid silver'};
        box-shadow: ${(props) =>
          props.input.border ? props.input.border : 'none'};
        margin: ${(props) => (props.input.margin ? props.input.margin : '0')};
        color: ${(props) => (props.input.color ? props.input.color : 'gray')};
        outline: none !important;
        font-size: ${(props) =>
          props.input.fontSize ? props.input.fontSize : '14px'};
        font-weight: ${(props) =>
          props.input.fontWeight ? props.input.fontWeight : '400'};

        &:focus-visible {
          color: ${(props) =>
            props.input.colorFocus
              ? props.input.colorFocus
              : 'black'} !important;
          background-color: ${(props) =>
            props.input.bgColorFocus
              ? props.input.bgColorFocus
              : 'white'} !important;
          border: ${(props) =>
            props.input.borderFocus
              ? props.input.borderFocus
              : '1px solid silver'} !important;
          outline: ${(props) => props.focusVisible.outline} !important;
          box-shadow: ${(props) => props.focusVisible.boxShadow} !important;
          transition: ${(props) => props.focusVisible.transition};
        }

        &:disabled,
        &[disabled] {
          color: ${(props) =>
            props.input.colorDisabled
              ? props.input.colorDisabled
              : 'silver'} !important;
          background-color: ${(props) =>
            props.input.bgColorDisabled
              ? props.input.bgColorDisabled
              : 'gray'} !important;
          border: ${(props) =>
            props.input.borderDisabled
              ? props.input.borderDisabled
              : '1px solid silver'} !important;
        }
      }
    }

    .xs2a-submit,
    .xs2a-back,
    .xs2a-restart,
    .xs2a-abort {
      outline: none;
      padding: ${(props) =>
        props.button.padding ? props.button.padding : '10px 15px'};
      background-color: ${(props) =>
        props.button.bgColor ? props.button.bgColor : '#2c60ff'};
      border-radius: ${(props) =>
        props.button.borderRadius ? props.button.borderRadius : '8px'};
      min-width: ${(props) =>
        props.button.minWidth ? props.button.minWidth : 'auto'};
      width: ${(props) => (props.button.width ? props.button.width : 'auto')};
      border: ${(props) =>
        props.button.border ? props.button.border : '1px solid #2c60ff'};
      box-shadow: ${(props) =>
        props.button.boxShadow ? props.button.boxShadow : 'none'};
      margin: ${(props) => (props.button.margin ? props.button.margin : '0')};
      color: ${(props) =>
        props.button.color ? props.button.color : '#FFFFFF'};
      display: flex;
      cursor: pointer;
      align-items: center;
      font-size: ${(props) =>
        props.button.fontSize ? props.button.fontSize : '14px'};
      font-weight: ${(props) =>
        props.button.fontWeight ? props.button.fontWeight : '500'};

      &:hover {
        color: ${(props) =>
          props.button.colorHover ? props.button.colorHover : '#ffffff'};
        background-color: ${(props) =>
          props.button.bgColorHover ? props.button.bgColorHover : '#0d319d'};
        border: ${(props) =>
          props.button.borderHover
            ? props.button.borderHover
            : '1px solid #0d319d'};
      }

      &:disabled,
      &[disabled] {
        background-color: ${(props) =>
          props.button.bgColorDisabled
            ? props.button.bgColorDisabled
            : '#9dafe5'};
        border: ${(props) =>
          props.button.borderDisabled
            ? props.button.borderDisabled
            : '1px solid #9dafe5'};
        color: ${(props) =>
          props.button.colorDisabled ? props.button.colorDisabled : '#dee6ff'};
      }

      @media (max-width: 600px) {
        width: ${(props) =>
          props.button.widthMobile ? props.button.widthMobile : '100%'};
        padding: ${(props) =>
          props.button.paddingMobile
            ? props.button.paddingMobile
            : '10px 15px'};
      }

      &:focus-visible {
        outline: ${(props) => props.focusVisible.outline} !important;
        box-shadow: ${(props) => props.focusVisible.boxShadow} !important;
        transition: ${(props) => props.focusVisible.transition};
      }
    }

    .xs2a-back {
      order: 0 !important;
      color: ${(props) =>
        props.backButton.color
          ? `${props.backButton.color} !important`
          : '#000000'};
      background-color: transparent;
      border: none !important;
      border-bottom: ${(props) =>
        props.backButton.borderColor
          ? `${props.backButton.borderColor} 2px solid !important`
          : '#000000'};

      &:hover {
        color: ${(props) =>
          props.backButton.colorHover
            ? `${props.backButton.colorHover} !important`
            : '#ffffff'};
        background-color: transparent !important;
        border: none !important;
        border-bottom: ${(props) =>
          props.backButton.borderColor
            ? `${props.backButton.borderColor} 2px solid !important`
            : '#efefef'};
      }

      &:disabled,
      &[disabled] {
        background-color: transparent;
        border: ${(props) =>
          props.backButton.disabled
            ? props.backButton.disabled
            : `2px solid ${props.backButton.disabled}`};
        color: ${(props) =>
          props.button.colorDisabled ? props.button.colorDisabled : '#dee6ff'};
      }

      @media (min-width: 900px) {
        order: 0 !important;
      }

      @media (max-width: 600px) {
        margin-top: 10px !important;
        order: 2 !important;
      }
    }

    .xs2a-completion-results {
      border: ${(props) =>
        props.input.border ? props.input.border : '1px solid silver'};

      .xs2a-result-line1 {
        color: ${(props) =>
          props.input.colorFocus ? props.input.colorFocus : '#2c60ff'};
      }

      .xs2a-result-line2 {
        color: ${(props) => (props.input.color ? props.input.color : 'gray')};
      }
    }

    .xs2a-checkbox {
      position: relative;

      &:focus-visible {
        outline: ${(props) => props.focusVisible.outline} !important;
        box-shadow: ${(props) => props.focusVisible.boxShadow} !important;
        transition: ${(props) => props.focusVisible.transition};
      }
    }

    .xs2a-checkbox label {
      line-height: 18px !important;
      position: relative;
      width: 100%;
      padding-left: 32px !important;
      margin-bottom: 0px !important;
      margin-top: 20px !important;
      color: rgb(80, 80, 80) !important;
    }

    .xs2a-checkbox input {
      -moz-appearance: initial;
      padding: 0;
      width: 24px;
      height: 24px;
      margin-bottom: 0;
      display: block;
      cursor: pointer;
      position: absolute;
      top: 13px;
      left: 0;
      visibility: hidden;
    }

    .xs2a-checkbox input:before {
      width: 20px;
      height: 20px;
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: ${(props) =>
        props.checkboxColor
          ? `${props.checkboxColor} 2px solid !important`
          : '#2c60ff 2px solid !important'};
      padding: 8px;
      display: inline-block;
      position: absolute;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      top: 0px;
      left: 0;
      box-sizing: border-box;
      visibility: visible;
    }

    .xs2a-checkbox input:checked:before {
      background-color: ${(props) =>
        props.checkboxColorActive ? props.checkboxColorActive : '#2c60ff'};
      border: ${(props) =>
        props.checkboxColorActive
          ? `${props.checkboxColorActive} 2px solid !important`
          : '#2c60ff 2px solid !important'};
    }

    .xs2a-checkbox input:checked:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 7px;
      width: 6px;
      height: 9.5px;
      border: solid
        ${(props) => (props.bgColor ? `${props.bgColor}` : '#2c60ff')};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      visibility: visible;
    }

    label[for='XS2A-privacy_policy'] {
      font-size: 80% !important;
    }

    .xs2a-submit-line {
      margin-top: 40px !important;
    }

    .xs2a-image {
      margin-left: 0 !important;
      img {
        border: ${(props) =>
          props.input.border ? props.input.border : '1px solid silver'};
      }
    }

    .xs2a-logo img {
      border: none !important;
    }

    .xs2a-error .xs2a-icon-paragraph {
      box-sizing: border-box;
      padding: 10px !important;
      line-height: 16px !important;
    }

    .xs2a-error {
      margin: 30px 5px !important;
      box-sizing: border-box !important;
      display: inline-block !important;
      background-color: white !important;
      border: 1px solid #ff2f2f !important;
      color: #ff2f2f !important;
    }
  }
`
