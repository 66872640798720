import { Theme } from '@/constants/themes/theme'
import { SdkParameters } from 'onfido-sdk-ui'

export const getOnfidoTheme = (theme: Theme): SdkParameters['theme'] => {
  const onfidoTheme: SdkParameters['theme'] = {
    name: 'light',
    config: {
      fontWeightBody: 400,
      fontSizeBody: '16px',
      fontSizeSubtitle: '16px',
      fontSizeTitle: '40px',
      borderStyleSurfaceModal: 'none',
      colorBackgroundSurfaceModal: theme.container.bgColor,
      colorBackgroundIcon: '#e8ebec',

      colorBorderLinkUnderline: theme.button.bgColor,
      colorBackgroundLinkActive: 'transparent',
      colorBackgroundLinkHover: 'transparent',
      colorContentLinkTextHover: theme.globals.linkColorHover,

      colorContentBody: theme.container.color,
      colorContentTitle: theme.container.color,
      colorContentSubtitle: theme.container.color,

      borderRadiusButton: theme.button.borderRadius,
      colorContentButtonPrimaryText: theme.button.color,
      colorBackgroundButtonPrimary: theme.button.bgColor,
      colorBackgroundButtonPrimaryHover: theme.button.bgColorHover,
      colorBackgroundButtonPrimaryActive: theme.button.bgColor,

      colorContentButtonSecondaryText: theme.button.bgColor,
      colorBorderButtonSecondary: theme.button.bgColor,
      colorBackgroundButtonSecondary: theme.buttonSecondary.bgColor,
      colorBackgroundButtonSecondaryHover: theme.buttonSecondary.bgColorHover,
      colorBackgroundButtonSecondaryActive: theme.buttonSecondary.bgColor,

      colorBorderDocTypeButton: theme.buttonSecondary.border,
      colorBackgroundDocTypeButton: theme.buttonSecondary.bgColor,
      colorBackgroundDocTypeButtonHover: '#e8ebec',
      colorBackgroundDocTypeButtonActive: '#e8ebec',
      colorContentDocTypeButton: theme.buttonSecondary.color,
      colorContentDocTypeButtonHover: theme.buttonSecondary.colorHover,
      colorContentDocTypeButtonActive: theme.buttonSecondary.colorHover,
      colorBorderDocTypeButtonHover: theme.buttonSecondary.borderHover,

      colorIcon: theme.globals.linkColor,
      colorContentInfoPill: theme.button.color,
      colorBackgroundInfoPill: theme.button.bgColor,
      colorBackgroundSelector: theme.textInput.bgColor,
      colorBackgroundDropdownItemHover: theme.textInput.bgColorFocus,
      colorInputOutline: theme.textInput.border,
      colorContentButtonTertiaryText: theme.textInput.color,
      colorContentInput: theme.textInput.color,
      colorBackgroundInput: theme.textInput.bgColor,
      colorBorderInput: theme.textInput.border,
    },
  }

  return onfidoTheme
}
