import styled from 'styled-components'

export const MicropaymentInfoGraph = styled.div`
  width: 100%;
  margin: 0 0 30px 0;
`

export const MicropaymentNamensabgleich = styled.div`
  width: 100%;
  margin: 30px 0 30px 0;
`

export const TinkInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 36px 0;

  img {
    width: 69px;
  }
`

export const CenterLiner = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const LineHeight = styled.div`
  line-height: ${(props) => (props.height ? props.height : 'auto')};
`

export const IkanoStyleWrap = styled.div`
  h1 {
    font-weight: 900;
    margin: 0 0 30px 0 !important;
  }
`

export const IkanoInfoContainer = styled.div`
  width: 100%;

  @media (max-width: 900px) {
    padding-top: 60px;
  }

  svg {
    width: 100%;
  }
`

export const IconContainer = styled.div`
  width: 100%;
  margin: 0 0 30px 0;

  svg {
    width: 100%;
  }
`

export const HorizontalIconContainer = styled.div<{
  margin?: string
  marginMobile?: string
}>`
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0 0 30px 0')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 980px) {
    margin: ${(props) =>
      props.marginMobile ? props.marginMobile : '0 0 30px 0'};
  }
`
