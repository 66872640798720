import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Container } from './SelectInput.styles'

export interface SelectOption {
  value: string
  label: string
}

interface SelectInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  options: SelectOption[]
  value: null | SelectOption | SelectOption[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void
  autoFocus?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  className?: any
  isClearable?: boolean
  isDisabled?: boolean
  isMulti?: boolean
  isSearchable?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadingMessage?: any
  menuPlacement?: 'bottom' | 'auto' | 'top'
  borderRadius?: string
  danger?: string
  dangerLight?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components?: any
  defaultMenuIsOpen?: boolean
  placeholder?: string
  borderWidth?: string
  color?: string
  colorDisabled?: string
  backgroundColor?: string
  borderColorFocus?: string
  colorFocus?: string
  backgroundColorFocus?: string
  colorSelected?: string
  backgroundColorSelected?: string
  menuMarginTop?: string
  menuBorderWidth?: string
  indicatorColorFocus?: string
  indicatorColorHover?: string
  borderColor?: string
  showCheckmark?: boolean
}

export const SelectInput = (props: SelectInputProps) => {
  const [selectValue, setSelectValue] = useState(null)

  function handleChange(value) {
    setSelectValue(value)
    props.onChange(value)
  }

  useEffect(() => setSelectValue(props.value), [props.value])

  const selectRef = useRef(null)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      selectRef.current.onMenuOpen()
    }
  }

  return (
    <Container
      borderWidth={props.borderWidth}
      color={props.color}
      colorDisabled={props.colorDisabled}
      borderColor={props.borderColor}
      borderRadius={props.borderRadius}
      backgroundColor={props.backgroundColor}
      borderColorFocus={props.borderColorFocus}
      colorFocus={props.colorFocus}
      backgroundColorFocus={props.backgroundColorFocus}
      colorSelected={props.colorSelected}
      backgroundColorSelected={props.backgroundColorSelected}
      menuMarginTop={props.menuMarginTop}
      menuBorderWidth={props.menuBorderWidth}
      indicatorColorFocus={props.indicatorColorFocus}
      indicatorColorHover={props.indicatorColorHover}
      showCheckmark={props.showCheckmark}
    >
      <Select
        ref={selectRef}
        onKeyDown={handleKeyDown}
        options={props.options}
        value={selectValue}
        onChange={handleChange}
        defaultMenuIsOpen={
          props.defaultMenuIsOpen ? props.defaultMenuIsOpen : false
        }
        className={props.className ? props.className : 'select-input'}
        classNamePrefix={props.className ? props.className : 'select-input'}
        autoFocus={props.autoFocus ? props.autoFocus : false}
        isClearable={props.isClearable ? props.isClearable : false}
        isDisabled={props.isDisabled ? props.isDisabled : false}
        isMulti={props.isMulti ? props.isMulti : false}
        isSearchable={props.isSearchable ? props.isSearchable : true}
        loadingMessage={
          props.loadingMessage ? props.loadingMessage : <>Fetching options</>
        }
        menuPlacement={props.menuPlacement ? props.menuPlacement : 'auto'}
        components={props.components ? props.components : null}
        placeholder={props.placeholder ? props.placeholder : 'Select'}
        closeMenuOnSelect
      />
    </Container>
  )
}
