import {
  onKeyDownHandler,
  dialNumberSelectorHandler,
} from '@/methods/keyboardAccessibility'
import { importantCountries } from '../constants/importantCountries'

export const handlePhonePrefix = (phone: string) => {
  const telephoneCodeWidth = String(
    document.getElementsByClassName('eid-telephone-code')[0].clientWidth
  )
  const countryListDropdown = document.getElementsByClassName(
    'iti__country-list'
  )[0] as HTMLElement
  countryListDropdown.style.width = telephoneCodeWidth

  const selectedFlag = document.getElementsByClassName(
    'iti__selected-flag'
  )[0] as HTMLElement

  selectedFlag.onkeydown = function (event) {
    onKeyDownHandler(event, () => {
      event.preventDefault()
      dialNumberSelectorHandler()
      ;(this as HTMLElement).click()
    })
  }

  selectedFlag.click()

  const listElements = document.querySelectorAll('.iti__country-name')

  if (listElements.length) {
    const filteredDialNumbers = Array.from(listElements)
      .map((item) =>
        item.innerHTML
          .replace('<span class="iti__dial-code">', '|')
          .replace('</span>', '')
          .replace(/\s/g, '')
      )
      .map((item, i) =>
        item.split('|')[1] === phone.substring(0, 3) ||
        item.split('|')[1] === phone.substring(0, 4)
          ? { index: i, name: item.split('|')[0], prefix: item.split('|')[1] }
          : undefined
      )
      .filter((x) => x)

    if (filteredDialNumbers.length) {
      if (filteredDialNumbers.length > 1) {
        const filteredDialNumber = filteredDialNumbers.filter((item) =>
          importantCountries.includes(item.name)
        )
        if (filteredDialNumber.length) {
          ;(listElements[filteredDialNumber[0].index] as HTMLElement).click()
        } else {
          ;(listElements[filteredDialNumbers[0].index] as HTMLElement).click()
        }
      } else {
        ;(listElements[filteredDialNumbers[0].index] as HTMLElement).click()
      }
    }
    return phone.slice(filteredDialNumbers[0].prefix.length)
  } else {
    return phone.slice(3)
  }
}
