import {
  Button,
  DocumentCheckbox,
  Header,
  Icon,
  PdfViewer,
  Popup,
  WrapKontomatik,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PrivacyPolicy from '../../assets/privacy_policy.pdf'
import PrivacyPolicyDe from '../../assets/privacy_policy_de.pdf'
import { StoreContext } from '../../components/App'
import { actionReport } from '../../methods/actionReport'
import {
  ButtonImgWrap,
  ButtonText,
  IntroDocIcon,
  IntroInfo,
  IntroInfoContent,
  IntroInfoIcon,
  IntroKontomatikContent,
  IntroParagraph,
} from '../../styles/generic.styles'
import { LineHeight } from '../../styles/ikano.styles'
import { WelcomeCheck } from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../../styles/layout.styles'

export const IntroKontomatikPage = observer(() => {
  const store = useContext(StoreContext)

  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState
  const { pageWidth, pageHeight } = store.AppState
  const { language } = store.TranslationsState

  const { introKontomatikPage: trans, klarnaPrivacyPolicyPage: policyPage } =
    store.TranslationsState.translations

  const [signed, setSigned] = useState(false)
  const [openBanking, setOpenBanking] = useState(false)

  // Popups visibility
  const [openBankingPopupVisible, setOpenBankingPopupVisible] = useState(false)

  // Mobile utilities
  const [isMobile, setIsMobile] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
      setIsMobile(true)
    }
  }, [pageWidth, pageHeight])

  const handleOpenBanking = ({ checked: value }) => {
    actionReport({
      type: 'event.onboarding-web.consent.OPEN_BANKING_CHECKED',
      payload: {},
    })
    setOpenBanking(value)
  }

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (openBanking === true) {
      setSigned(true)
      actionReport({
        type: 'event.onboarding-web.consent.CONSENT_ACCEPTED',
        payload: {},
      })
    } else {
      setSigned(false)
    }
  }, [openBanking])

  const isTouchScrollDisabled = () => {
    if (openBankingPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  useEffect(() => {
    if (openBankingPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.OPEN_BANKING_DISPLAYED',
        payload: {},
      })
    }
  }, [openBankingPopupVisible])

  return (
    <WrapKontomatik
      {...theme.wrapKontomatik}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>

      <IntroKontomatikContent>
        <IntroDocIcon>
          <svg
            width="80"
            height="81"
            viewBox="0 0 80 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="40" cy="40.2832" r="40" fill="#EDEEEF" />
            <path
              d="M56 25.7377L56 59.9196C56 62.3283 54.0465 64.2832 51.6364 64.2832L28.3636 64.2832C25.9549 64.2832 24 62.3283 24 59.9196L24 20.6468C24 18.2367 25.9549 16.2832 28.3636 16.2832L46.5455 16.2832L56 25.7377Z"
              fill="white"
            />
            <path
              d="M46.5455 16.2832L28.3636 16.2832C25.9549 16.2832 24 18.2367 24 20.6468L24 59.9196C24 62.3283 25.9549 64.2832 28.3636 64.2832L51.6364 64.2832C54.0465 64.2832 56 62.3283 56 59.9196L56 25.7377M46.5455 16.2832L56 25.7377M46.5455 16.2832L46.5455 23.7378C46.5455 24.8423 47.4409 25.7377 48.5455 25.7377L56 25.7377"
              stroke="#2C3E4F"
              strokeWidth="2"
            />
            <path
              d="M42 58.2832H51.5"
              stroke="#2C3E4F"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M39.6364 22.4411C39.6364 21.8016 39.1073 21.2832 38.4546 21.2832C37.8018 21.2832 37.2727 21.8016 37.2727 22.4411V22.5404L36.4192 21.7042C35.9115 21.2068 35.0885 21.2068 34.5808 21.7042L29.3461 26.8329C28.8846 27.285 28.8846 28.0182 29.3461 28.4704C29.7328 28.8492 30.3208 28.9107 30.7727 28.6547V32.2832H34.3182V29.9674C34.3182 29.3279 34.8473 28.8095 35.5 28.8095C36.1527 28.8095 36.6818 29.3279 36.6818 29.9674V32.2832H40.2273V28.6547C40.6792 28.9107 41.2672 28.8492 41.6539 28.4704C42.1154 28.0182 42.1154 27.285 41.6539 26.8329L39.6364 24.8562V22.4411Z"
              fill="#2C3E4F"
            />
            <path
              d="M30 38.2832H51"
              stroke="#2C3E4F"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M30 44.2832H51"
              stroke="#2C3E4F"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M30 50.2832H51"
              stroke="#2C3E4F"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </IntroDocIcon>

        <IntroParagraph>{trans.intro}</IntroParagraph>

        <IntroInfo>
          <IntroInfoIcon>
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 2124640">
                <circle
                  id="Ellipse 317"
                  cx="11"
                  cy="11.2832"
                  r="10"
                  fill="white"
                  stroke="#2A0760"
                  strokeWidth="2"
                />
                <g id="Union">
                  <path
                    d="M11 16.2832C10.4477 16.2832 10 15.8355 10 15.2832L10 10.2832C10 9.73092 10.4477 9.2832 11 9.2832C11.5523 9.2832 12 9.73092 12 10.2832L12 15.2832C12 15.8355 11.5523 16.2832 11 16.2832Z"
                    fill="#2A0760"
                  />
                  <path
                    d="M11 8.2832C11.5523 8.2832 12 7.83549 12 7.2832C12 6.73092 11.5523 6.2832 11 6.2832C10.4477 6.2832 10 6.73092 10 7.2832C10 7.83549 10.4477 8.2832 11 8.2832Z"
                    fill="#2A0760"
                  />
                </g>
              </g>
            </svg>
          </IntroInfoIcon>
          <IntroInfoContent>
            <span>{trans.accountInformation}</span>
            <ul>
              <li>{trans.names}</li>
              <li>{trans.address}</li>
              <li>{trans.ownership}</li>
            </ul>
          </IntroInfoContent>
        </IntroInfo>

        <IntroParagraph
          dangerouslySetInnerHTML={{ __html: trans.verificationPurposes }}
        />
      </IntroKontomatikContent>

      {/* Open Banking */}
      <WelcomeCheck margin="0 0 10px 0">
        <LineHeight height="141.99%">
          {/* <ConditionTitle required asteriskColor={theme.globals.inputRequired.asteriskColor}>{'Open Banking'}</ConditionTitle> */}
          <DocumentCheckbox
            inputName="iddata-checkbox"
            description={policyPage.consent}
            externalUrl={''}
            handleDecision={handleOpenBanking}
            value={openBanking}
            {...theme.documentCheckbox}
            margin="0 0 30px 0"
            contentFontsize={theme.globals.contentFontsize}
            iconSize="26px"
            onClick={() => setOpenBankingPopupVisible(true)}
            tabIndex={openBankingPopupVisible ? -1 : 0}
          />
        </LineHeight>
      </WelcomeCheck>

      <ContentContainerBottomFixed>
        {!isMobile ? <ContentContainerBottomLeft /> : <div />}
        <Button
          onClick={() => handleProceed()}
          {...theme.button}
          disabled={!signed}
          width="164px"
          paddingMobile="14px 40px"
        >
          <>
            <ButtonImgWrap $hide={theme.wcag}>
              <Icon size="18px" type="checkmark" />
            </ButtonImgWrap>
            <ButtonText>{trans.proceed}</ButtonText>
          </>
        </Button>
      </ContentContainerBottomFixed>
      <MobileSpacerForContentContainerBottomFixed />

      {isMobile ? (
        <>
          <Popup
            visibility={openBankingPopupVisible}
            handleVisibility={setOpenBankingPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              viewerWidth="100%"
              viewerHeight={containerPdfViewerHeight}
              initialScale={1}
            />
          </Popup>
        </>
      ) : (
        <>
          <Popup
            visibility={openBankingPopupVisible}
            handleVisibility={setOpenBankingPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              viewerWidth="100%"
              viewerHeight={`calc(100vh - 405px)`}
              initialScale={1}
            />
          </Popup>
        </>
      )}
    </WrapKontomatik>
  )
})
