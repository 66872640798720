import { observer } from 'mobx-react'
import styled from 'styled-components'
import React, { FC, useContext } from 'react'
import { StoreContext } from './App'
import AmexHeaderLogo from '@assets/headers/amex-header-logo.png'
import { NordaxHeaderLogo } from '@/assets/headers/nordax-bank-logo'

const AmexHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  background-color: #fff;
`

const NordaxHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  height: 65px;
  background-color: #09264e;
`

export const TopBar: FC = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  if (theme.globals.name === 'Amex-demo') {
    return (
      <AmexHeader>
        <img src={AmexHeaderLogo} alt="Amex logo" />
      </AmexHeader>
    )
  }

  if (theme.globals.name === 'Nordax') {
    return (
      <NordaxHeader>
        <NordaxHeaderLogo />
      </NordaxHeader>
    )
  }

  return <></>
})
