export const onKeyDownHandler = (event, callback) => {
  if (event.key === 'Enter' || event.key === ' ') {
    callback()
  }
}

export const buttonsHandler = () => {
  const buttons = document.querySelectorAll('button, a')

  buttons.forEach(function (button: HTMLButtonElement) {
    button.setAttribute('tabindex', '0')

    const originalOnClickHandler = button.onclick

    if (originalOnClickHandler) {
      button.onkeydown = function (event) {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          originalOnClickHandler.call(this, event)
        }
      }
    } else if (button.tagName.toLowerCase() === 'a') {
      button.onkeydown = function (event) {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          ;(this as HTMLElement).click()
        }
      }
    }
  })
}

export const dialNumberSelectorHandler = () => {
  const selectInput = document.getElementsByClassName(
    'iti__countrylist__input'
  )[0] as HTMLElement

  selectInput.onkeydown = function (event) {
    if (event.key === 'ArrowDown') {
      const dropdownElements = document.querySelectorAll('.iti__country')
      const activeElements = Array.prototype.filter.call(
        dropdownElements,
        function (element) {
          return window.getComputedStyle(element).display === 'block'
        }
      )

      activeElements.forEach(function (element, index) {
        element.onkeydown = function (event) {
          if (event.key === 'ArrowDown') {
            // Prevent default scrolling
            event.preventDefault()
            // Focus next element if available
            if (index < activeElements.length - 1) {
              activeElements[index + 1].focus()
            }
          } else if (event.key === 'ArrowUp') {
            // Prevent default scrolling
            event.preventDefault()
            // Focus previous element if available
            if (index > 0) {
              activeElements[index - 1].focus()
            }
          } else if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault()
            activeElements[index].click()
          }
        }
      })

      // Prevent default scrolling
      event.preventDefault()
      if (activeElements.length > 0) {
        activeElements[0].focus()
      }
    }
  }
}
