import { ContentContainer, LoadingSpinner } from '../components/Generic'
import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'

import { ScanQR } from '../components/SwitchToMobile/steps/ScanQR'
import { ShowPin } from '../components/SwitchToMobile/steps/ShowPin'
import { devLog } from '../methods/devLog'
import { forceRedirect } from '../methods/forceRedirect'
import { TestProceed } from '../styles/generic.styles'
import { WaitingForMobile } from '../components/SwitchToMobile/steps/WaitingForMobile'
import { getOnboardingContext } from '../methods/getOnboardingContext'

export interface GetSwitchToMobileContextResponse {
  isFinishOnDesktopAfterPollingEnabled: boolean
  sms: {
    enabled: boolean
    maxAttempts: number
    retrySeconds: number
  }
  pinCode: {
    maxAttempts: number
  }
  endpoints: {
    reportDesktopCameraUrl: string
    stayOnDeviceUrl: string
    getProceedLinkUrl: string
    sendSmsUrl: string
    isMobileSessionStartedUrl: string
    closeDesktopSessionUrl: string
    startMobileSessionUrl: string
    closeMobileSessionUrl: string
    generatePinCodeUrl: string
    isPinCodeConsumedUrl: string
    verifyPinCodeUrl: string
    isUserJourneyFinishedUrl: string
  }
}

export const SwitchToMobilePage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const [step, setStep] = useState(0)
  const apiUrl = process.env.WEB_API_URL

  const [context, setContext] = useState<
    GetSwitchToMobileContextResponse | undefined
  >()

  const {
    generatePinCodeUrl,
    getProceedLinkUrl,
    isMobileSessionStartedUrl,
    isPinCodeConsumedUrl,
    sendSmsUrl,
    stayOnDeviceUrl,
    closeDesktopSessionUrl,
    isUserJourneyFinishedUrl,
  } = context?.endpoints || {}

  const [isCameraAvailable, setIsCameraAvailable] = useState(false)

  const checkIfUserHasWebcam = async (reportUrl: string) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()

      const hasWebcam = devices.some((device) => device.kind === 'videoinput')

      setIsCameraAvailable(hasWebcam)

      await axios.post(
        `${reportUrl}`,
        { isDesktopCameraAvailable: true }, // uses hasWebcam originally. Turned off to show the generic flow of handling this via the browser and not decide for the user instead.
        {
          withCredentials: true,
        }
      )
    } catch (error) {
      devLog(error)
    }
  }

  const nextStep = () => {
    setStep(step + 1)
  }

  const initComponent = async () => {
    try {
      const { data } = await axios.get<GetSwitchToMobileContextResponse>(
        `${apiUrl}/${process.env.GO_TO_MOBILE_WEB_API_PATH}/get-context`,
        {
          withCredentials: true,
        }
      )

      await checkIfUserHasWebcam(data.endpoints.reportDesktopCameraUrl)

      devLog(data)
      setContext(data)

      nextStep()
    } catch (error) {
      devLog(error)
    }
  }

  useEffect(() => {
    devLog('initComponent')
    void initComponent()
  }, [])

  const renderCurrentStep = () => {
    if (!context)
      return (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      )

    switch (step) {
      case 0:
        return (
          <LoadingSpinner
            width="68px"
            padding="100px 0"
            {...theme.loadingSpinner}
          />
        )
      case 1:
        return (
          <ScanQR
            nextStep={nextStep}
            stayUrl={stayOnDeviceUrl}
            smsUrl={sendSmsUrl}
            smsMaxAttempts={context?.sms?.maxAttempts}
            isSmsEnabled={context?.sms?.enabled}
            isCameraAvailable={isCameraAvailable}
            retrySeconds={context?.sms?.retrySeconds}
            getProceedLinkUrl={getProceedLinkUrl}
            isMobileSessionStartedUrl={isMobileSessionStartedUrl}
          />
        )
      case 2:
        return (
          <ShowPin
            nextStep={nextStep}
            stayUrl={stayOnDeviceUrl}
            generatePinCodeUrl={generatePinCodeUrl}
            isPinCodeConsumedUrl={isPinCodeConsumedUrl}
            closeDesktopSessionUrl={closeDesktopSessionUrl}
            shouldProceedToPollingStep={
              context?.isFinishOnDesktopAfterPollingEnabled
            }
          />
        )

      case 3:
        return (
          <WaitingForMobile
            isUserJourneyFinishedUrlUrl={isUserJourneyFinishedUrl}
            closeDesktopSessionUrl={closeDesktopSessionUrl}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {renderCurrentStep()}
      <TestProceed
        className="test-proceed"
        onClick={() => {
          if (stayOnDeviceUrl?.length) {
            forceRedirect(stayOnDeviceUrl)
          }
        }}
      >
        .
      </TestProceed>
    </ContentContainer>
  )
})
