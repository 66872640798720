import React from 'react'
import {
  YoutubeEmbedStyled,
  YoutubeEmbedIframeStyled,
} from './YoutubeEmbed.styles'

interface YoutubeEmbedProps {
  embedVideo: string
  tabIndex?: number
}

export const YoutubeEmbed = ({ embedVideo, tabIndex }: YoutubeEmbedProps) => (
  <YoutubeEmbedStyled>
    <YoutubeEmbedIframeStyled
      tabIndex={tabIndex || 0}
      width="853"
      height="480"
      src={`${embedVideo}?enablejsapi=1&origin=${window.location.origin}`}
    />
  </YoutubeEmbedStyled>
)
