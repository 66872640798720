import { observer } from 'mobx-react'
import styled from 'styled-components'
import React, { useContext, useEffect, useState } from 'react'
import { Onfido } from 'onfido-sdk-ui'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'
import { devLog } from '../methods/devLog'
import { ContentContainer, LoadingSpinner } from '../components/Generic'
import { getOnfidoTheme } from '@/methods/getOnfidoTheme'
import { forceRedirect } from '@/methods/forceRedirect'
import { SignicatBranding } from '@/components/SignicatBranding/SignicatBranding'

interface PostStartOnfidoResponse {
  sdkToken: string
  steps: []
}

const apiUrl = process.env.WEB_API_URL

const OnfidoMount = styled.div.attrs({
  id: 'onfido-mount',
})`
  width: 100%;
  min-height: 95vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  margin-top: -60px;

  #onfido-sdk {
    flex: 1;
  }
`

export const OnfidoPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { language } = store.TranslationsState
  const [isLoading, setIsLoading] = useState(true)

  async function handleAuth() {
    setIsLoading(true)

    try {
      const { data } = await axiosInstance.post<PostStartOnfidoResponse>(
        `${apiUrl}/onfido-v2/start-session`,
        {},
        {
          withCredentials: true,
        }
      )

      mountOnfido(data)
    } catch (e) {
      devLog(e)
    }
  }

  const mountOnfido = (onfidoData: PostStartOnfidoResponse): void => {
    Onfido.init({
      token: onfidoData.sdkToken,
      containerId: 'onfido-mount',
      enterpriseFeatures: {
        cobrand: {
          text: 'Signicat',
        },
      },
      theme: getOnfidoTheme(theme),
      language: language === 'en' ? 'en_US' : language,
      // language: 'nl',
      steps: onfidoData.steps,
      onComplete: () => {
        forceRedirect(`${process.env.WEB_API_URL}/onfido-v2/complete`)
      },
    })

    setIsLoading(false)
  }

  useEffect(() => {
    handleAuth()
  }, [])

  if (isLoading) {
    return (
      <ContentContainer
        {...theme.container}
        width="560px"
        padding="0px"
        paddingMobile="0px"
        marginMobile="10px"
      >
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
        <SignicatBranding width="560px" hideOnMobile />
      </ContentContainer>
    )
  } else {
    return (
      <OnfidoMount>
        <SignicatBranding width="560px" hideOnMobile />
      </OnfidoMount>
    )
  }
})
