import React from 'react'

interface ExternalLinkProps {
  color: string
  thickness?: string
}

export const ExternalLink = ({ color, thickness }: ExternalLinkProps) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9921 4.87494C15.9921 4.48604 16.3074 4.17078 16.6963 4.17078H21.0791C21.4933 4.17078 21.8291 4.50656 21.8291 4.92077L21.8291 9.36102C21.8291 9.74992 21.5138 10.0652 21.1249 10.0652C20.736 10.0652 20.4208 9.74993 20.4208 9.36103L20.4208 6.57495L14.0395 12.9562C13.7645 13.2312 13.3187 13.2312 13.0437 12.9562C12.7687 12.6812 12.7687 12.2354 13.0437 11.9604L19.4249 5.57911H16.6963C16.3074 5.57911 15.9921 5.26384 15.9921 4.87494ZM4.17078 10.2916C4.17078 7.50948 6.42614 5.25411 9.20828 5.25411H12.4583C12.8472 5.25411 13.1624 5.56938 13.1624 5.95828C13.1624 6.34718 12.8472 6.66244 12.4583 6.66244H9.20828C7.20394 6.66244 5.57911 8.28728 5.57911 10.2916V16.7916C5.57911 18.7959 7.20394 20.4208 9.20828 20.4208H15.7083C17.7126 20.4208 19.3374 18.7959 19.3374 16.7916V13.5416C19.3374 13.1527 19.6527 12.8374 20.0416 12.8374C20.4305 12.8374 20.7458 13.1527 20.7458 13.5416V16.7916C20.7458 19.5737 18.4904 21.8291 15.7083 21.8291H9.20828C6.42614 21.8291 4.17078 19.5737 4.17078 16.7916V10.2916Z"
        fill={`${color ? color : '#280b60'}`}
        stroke={`${color ? color : '#280b60'}`}
        strokeWidth={`${thickness ? thickness : '0'}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  )
}
