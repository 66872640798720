import React, { FC } from 'react'

export interface Base64File {
  name: string
  type: string
  size: number
  sizeMB: number
  base64: string
}

interface Base64FileInputProps {
  multiple?: boolean
  onChange: (file: Base64File) => void
}

export const Base64FileInput: FC<Base64FileInputProps> = ({
  multiple,
  onChange,
}) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader()
        reader.onload = () => {
          const base64 = reader.result as string
          onChange({
            name: file.name,
            type: file.type,
            size: file.size,
            sizeMB: file.size / 1024 / 1024,
            base64,
          })
        }
        reader.readAsDataURL(file)
      })
    }
  }

  return (
    <input
      type="file"
      id="base64FileInput"
      multiple={multiple}
      onChange={onChangeHandler}
      tabIndex={-1}
    />
  )
}
