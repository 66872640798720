import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import { IntroParagraph, Link } from '../../styles/generic.styles'
import { Divider } from '../../styles/jitpay.styles'
import {
  TermsAndConditions,
  AdditionalParagraphProps,
} from '../../components/TermsAndConditions/TermsAndConditions'

const AdditionalParagraph: React.FC<AdditionalParagraphProps> = ({
  tabIndex,
  theme,
  trans,
}) => (
  <>
    <IntroParagraph
      color={theme.globals.linkColor}
      colorHover={theme.globals.linkColorHover}
    >
      {trans.descriptionPartOne}
      <Link
        href="https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42"
        target="_blank"
        rel="noreferrer"
        tabIndex={tabIndex}
      >
        {trans.serviceProvider}
      </Link>
    </IntroParagraph>

    <Divider margin="30px 0" />
  </>
)

export const TermsAndConditionsWithMicropaymentIkanoPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { ikanoWelcomePage: trans } = store.TranslationsState.translations

  return (
    <TermsAndConditions
      header={trans.heading}
      showTitle={false}
      AdditionalParagraph={AdditionalParagraph}
      additionalParagraphProps={{ theme: theme, trans: trans }}
    />
  )
})
