import { createGlobalStyle } from 'styled-components'

export const AppStyle = createGlobalStyle`
    body {
        font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};

        @media (max-width: 980px) {
            font-size: ${(props) =>
              props.fontSizeMobile ? props.fontSizeMobile : '16px'};
        }
    }
`
