import React, { useEffect, useState } from 'react'
import { Container } from './Symbol.styles'

export const SymbolTypes = [
  'society-statement',
  'utility-bill',
  'driving-license',
  'letter',
  'passport',
  'identity-card',
  'globe',
  'camera',
  'euro',
  'lightbulb',
  'bank',
  'residence-permit',
  'right-arrow',
]

export interface SymbolProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  size: string
  type:
    | 'society-statement'
    | 'utility-bill'
    | 'driving-license'
    | 'letter'
    | 'passport'
    | 'identity-card'
    | 'globe'
    | 'camera'
    | 'euro'
    | 'lightbulb'
    | 'bank'
    | 'residence-permit'
    | 'micropayment'
    | 'right-arrow'
  primary?: string
  secondary?: string
  tenary?: string
  color?: string
  folder?: string
}

export const Symbol = ({
  size,
  type,
  primary,
  secondary,
  tenary,
  color,
  folder = 'common',
}: SymbolProps) => {
  const [IconComponent, setIconComponent] = useState(null)

  useEffect(() => {
    const loadIcon = async () => {
      switch (type) {
        case 'right-arrow': {
          const { RightArrow } = await import(`./symbols/${folder}/rightArrow`)
          setIconComponent(() => RightArrow)
          break
        }
        case 'society-statement': {
          const { SocietyStatement } = await import(
            `./symbols/${folder}/societyStatement`
          )
          setIconComponent(() => SocietyStatement)
          break
        }
        case 'utility-bill': {
          const { UtilityBill } = await import(
            `./symbols/${folder}/utilityBill`
          )
          setIconComponent(() => UtilityBill)
          break
        }
        case 'driving-license': {
          const { DrivingLicense } = await import(
            `./symbols/${folder}/drivingLicense`
          )
          setIconComponent(() => DrivingLicense)
          break
        }
        case 'letter': {
          const { Letter } = await import(`./symbols/${folder}/letter`)
          setIconComponent(() => Letter)
          break
        }
        case 'passport': {
          const { Passport } = await import(`./symbols/${folder}/passport`)
          setIconComponent(() => Passport)
          break
        }
        case 'identity-card': {
          const { IdentityCard } = await import(
            `./symbols/${folder}/identityCard`
          )
          setIconComponent(() => IdentityCard)
          break
        }
        case 'globe': {
          const { Globe } = await import(`./symbols/${folder}/globe`)
          setIconComponent(() => Globe)
          break
        }
        case 'camera': {
          const { Camera } = await import(`./symbols/${folder}/camera`)
          setIconComponent(() => Camera)
          break
        }
        case 'euro': {
          const { Euro } = await import(`./symbols/${folder}/euro`)
          setIconComponent(() => Euro)
          break
        }
        case 'lightbulb': {
          const { Lightbulb } = await import(`./symbols/${folder}/lightbulb`)
          setIconComponent(() => Lightbulb)
          break
        }
        case 'bank': {
          const { Bank } = await import(`./symbols/${folder}/bank`)
          setIconComponent(() => Bank)
          break
        }
        case 'residence-permit': {
          const { ResidencePermit } = await import(
            `./symbols/${folder}/residencePermit`
          )
          setIconComponent(() => ResidencePermit)
          break
        }
        case 'micropayment': {
          const { Micropayment } = await import(
            `./symbols/${folder}/micropayment`
          )
          setIconComponent(() => Micropayment)
          break
        }
        default:
          setIconComponent(null)
      }
    }

    loadIcon()
  }, [folder])

  if (!IconComponent) return null

  return (
    <Container
      size={size}
      primary={primary}
      secondary={secondary}
      tenary={tenary}
      color={color}
    >
      <IconComponent />
    </Container>
  )
}
