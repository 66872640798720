import { createGlobalStyle } from 'styled-components'

export const DocumentStyle = createGlobalStyle<{
  bgColor?: string
  fontFamily?: string
}>`
  body {
    background-color: ${(props) => (props.bgColor ? props.bgColor : 'gray')};
    padding: 0;
    margin: 0;
    font-family: ${(props) => (props.fontFamily ? props.fontFamily : '')};

    @media (max-width: 980px) {
      background-color: #ffffff;
    }

    button, a, label {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
    }

    button {
      justify-content: center;
    }

    html {
      height: fill-available;
      height: -webkit-fill-available;
    }
  }
`
