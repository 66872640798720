import React, { FC } from 'react'

export const NordaxHeaderLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="171"
    height="33"
    viewBox="0 0 171 33"
    fill="none"
  >
    <g clipPath="url(#clip0_10_10491)">
      <path
        d="M12.3 19.8008L19.7352 22.99V16.0814L12.3 5.70863V19.8008Z"
        fill="#69E3DB"
      />
      <path
        d="M19.7352 0L12.3 3.19316L26.5031 22.99L33.9384 19.8008L19.7352 0Z"
        fill="#69E3DB"
      />
      <path
        d="M33.9384 3.19316L26.5031 0V6.90855L33.9384 17.2853V3.19316Z"
        fill="#69E3DB"
      />
      <path
        d="M114.611 7.86132H111.82L109.009 11.784L106.199 7.86132H103.407L107.616 13.7294L103.407 19.5935H106.203L109.009 15.6788L111.816 19.5935H114.611L110.407 13.7294L114.611 7.86132Z"
        fill="#69E3DB"
      />
      <path
        d="M154.418 7.66598C155.709 7.66598 156.624 8.10848 157.429 8.9855C158.234 9.86651 158.638 11.1183 158.638 12.7408V19.5935H156.31V13.1314C156.31 11.9315 155.576 9.91834 153.546 9.92232C151.517 9.92232 150.787 11.9315 150.775 13.1394V19.5975H148.447V7.90118H150.221L151.073 9.1051C151.839 8.14834 152.954 7.66598 154.414 7.66598"
        fill="#69E3DB"
      />
      <path
        d="M140.67 17.8913C141.514 17.8913 142.252 17.6202 142.88 17.074C143.508 16.5279 143.826 15.7705 143.826 14.8057V14.1958L142.076 14.3353C139.909 14.5068 138.829 15.1845 138.829 16.3724C138.829 16.8707 139.002 17.2495 139.347 17.5086C139.693 17.7677 140.132 17.8953 140.67 17.8953M146.107 16.492V19.6015H144.333L143.709 18.7205C142.825 19.4659 141.722 19.8367 140.395 19.8367C139.229 19.8367 138.279 19.5496 137.549 18.9716C136.819 18.3936 136.454 17.5763 136.454 16.5159C136.454 14.2078 138.181 12.9122 141.636 12.6331L143.822 12.4697V12.1906C143.822 11.3814 143.626 10.7356 143.234 10.2612C142.841 9.78678 142.291 9.5476 141.585 9.5476C140.878 9.5476 140.305 9.75888 139.916 10.1775C139.524 10.6 139.292 11.0983 139.214 11.6764H136.956C137.062 10.5083 137.526 9.5476 138.338 8.79814C139.151 8.04868 140.227 7.67395 141.565 7.67395C142.904 7.67395 144.011 8.08456 144.847 8.90179C145.683 9.71901 146.103 10.8711 146.103 12.3541V16.496L146.107 16.492Z"
        fill="#69E3DB"
      />
      <path
        d="M45.2836 7.7497V19.5975H42.9596V3.22107H45.2444L53.5786 15.033V3.22107H55.9026V19.5975H53.7553L45.2836 7.7497Z"
        fill="#69E3DB"
      />
      <path
        d="M61.112 16.5439C61.7637 17.3013 62.6116 17.68 63.6559 17.68C64.7001 17.68 65.5481 17.3013 66.1997 16.5439C66.8514 15.7864 67.1772 14.8576 67.1772 13.7493C67.1772 12.6411 66.8475 11.7282 66.1879 10.9668C65.5284 10.2014 64.6766 9.82266 63.6323 9.82266C62.5881 9.82266 61.7637 10.2014 61.112 10.9588C60.4604 11.7162 60.1345 12.6451 60.1345 13.7533C60.1345 14.8616 60.4604 15.7904 61.112 16.5478M63.6559 19.8327C61.9678 19.8327 60.5585 19.2547 59.4279 18.1026C58.2973 16.9505 57.7359 15.4914 57.7359 13.7294C57.7359 11.9674 58.2973 10.5362 59.4161 9.39212C60.5349 8.24402 61.9403 7.67395 63.6323 7.67395C65.3243 7.67395 66.7336 8.24801 67.8721 9.39212C69.0066 10.5362 69.5758 11.9913 69.5758 13.7533C69.5758 15.5153 69.0144 16.9704 67.8956 18.1145C66.7729 19.2626 65.3636 19.8327 63.6559 19.8327Z"
        fill="#69E3DB"
      />
      <path
        d="M78.1338 10.0379C77.7334 9.86651 77.4115 9.83861 76.8344 9.8426C76.1082 9.85057 75.4172 10.1456 74.8048 10.7914C74.1924 11.4372 73.8038 12.3381 73.8038 13.8928V19.5975H71.4759V7.90118H73.2503L74.11 9.10908C75.111 7.88524 76.7638 7.47862 78.1338 7.71382"
        fill="#69E3DB"
      />
      <path
        d="M82.2283 16.5558C82.8407 17.2893 83.6259 17.6561 84.5798 17.6561C85.5338 17.6561 86.3032 17.2933 86.896 16.5678C87.4848 15.8422 87.7832 14.9014 87.7832 13.7493C87.7832 12.5972 87.4888 11.6564 86.896 10.9309C86.3032 10.2054 85.5338 9.84259 84.5798 9.84259C83.6259 9.84259 82.825 10.2054 82.2205 10.9309C81.612 11.6564 81.3097 12.5972 81.3097 13.7493C81.3097 14.9014 81.6159 15.8223 82.2322 16.5558M87.7832 2.94201H90.1111V19.5975H88.3367L87.477 18.3856C86.6565 19.3503 85.5691 19.8287 84.2108 19.8287C82.7073 19.8287 81.455 19.2626 80.4578 18.1345C79.4607 17.0023 78.9621 15.5432 78.9621 13.7493C78.9621 11.9554 79.4764 10.4725 80.5049 9.35226C81.5335 8.22807 82.8093 7.66997 84.3286 7.66997C85.9734 7.66997 87.1237 8.27193 87.7832 9.47185V2.946V2.94201Z"
        fill="#69E3DB"
      />
      <path
        d="M96.4118 17.8913C97.2559 17.8913 97.9939 17.6202 98.622 17.074C99.2501 16.5279 99.5681 15.7705 99.5681 14.8057V14.1958L97.8172 14.3353C95.6503 14.5068 94.5707 15.1845 94.5707 16.3724C94.5707 16.8707 94.7434 17.2495 95.0889 17.5086C95.4344 17.7677 95.874 17.8953 96.4118 17.8953M101.849 16.492V19.6015H100.075L99.4503 18.7205C98.5671 19.4659 97.4639 19.8367 96.1371 19.8367C94.9711 19.8367 94.0211 19.5496 93.2909 18.9716C92.5607 18.3936 92.1957 17.5763 92.1957 16.5159C92.1957 14.2078 93.923 12.9122 97.3776 12.6331L99.5642 12.4697V12.1906C99.5642 11.3814 99.3679 10.7356 98.9753 10.2612C98.5828 9.78678 98.0332 9.5476 97.3265 9.5476C96.6199 9.5476 96.0468 9.75888 95.6581 10.1775C95.2655 10.6 95.0339 11.0983 94.9554 11.6764H92.6981C92.8041 10.5083 93.2674 9.5476 94.08 8.79814C94.8926 8.04868 95.9682 7.67395 97.3069 7.67395C98.6456 7.67395 99.7526 8.08456 100.589 8.90179C101.425 9.71901 101.845 10.8711 101.845 12.3541V16.496L101.849 16.492Z"
        fill="#69E3DB"
      />
      <path
        d="M129.238 17.4448C130.22 17.4448 130.997 17.2176 131.566 16.7671C132.136 16.3166 132.418 15.6828 132.418 14.8735C132.418 14.0643 132.14 13.4025 131.578 12.8962C131.017 12.3899 130.263 12.1348 129.309 12.1348H126.129V17.4448H129.238ZM126.129 5.37376V10.0738H128.987C129.863 10.0738 130.538 9.85854 131.013 9.43199C131.488 9.00145 131.727 8.41544 131.727 7.66598C131.727 6.91653 131.476 6.36639 130.978 5.97173C130.479 5.57308 129.839 5.37376 129.054 5.37376H126.129ZM131.774 10.903C133.863 11.5568 134.907 13.0118 134.907 15.1047C134.907 16.5877 134.409 17.7039 133.408 18.4613C132.41 19.2188 131.158 19.5975 129.655 19.5975H123.805V3.22107H129.081C130.585 3.22107 131.802 3.57586 132.732 4.28545C133.663 4.99505 134.126 6.00362 134.126 7.31517C134.126 9.0453 133.341 10.2971 131.774 10.903Z"
        fill="#69E3DB"
      />
      <path
        d="M171 7.90118H168.005L163.333 12.8922L163.329 12.673V2.98985H160.982V19.5975H163.329V15.9698L164.727 14.5068L168.177 19.5975L171 19.5935L166.348 12.7886L171 7.90118Z"
        fill="#69E3DB"
      />
      <path
        d="M46.4692 29.6235H44.1295L43.5681 31.3217H42.9596L44.946 25.4616H45.6644L47.6508 31.3217H47.0345L46.4731 29.6235H46.4692ZM46.2965 29.1132L45.3229 26.1592H45.2719L44.2983 29.1132H46.2925H46.2965Z"
        fill="#69E3DB"
      />
      <path
        d="M54.5169 29.2408C54.5169 30.5643 53.7356 31.4094 52.6443 31.4094C51.8866 31.4094 51.4587 30.9988 51.2664 30.6799V33H50.7129V27.1399H51.1761L51.2271 27.7857H51.2782C51.4744 27.4827 51.8866 27.0562 52.6482 27.0562C53.7513 27.0562 54.5208 27.9332 54.5208 29.2408H54.5169ZM53.9712 29.2408C53.9712 28.2282 53.402 27.5585 52.5933 27.5585C51.7846 27.5585 51.2664 28.2282 51.2664 29.2408C51.2664 30.2533 51.7846 30.9071 52.5933 30.9071C53.402 30.9071 53.9712 30.2374 53.9712 29.2408Z"
        fill="#69E3DB"
      />
      <path
        d="M58.949 30.8792V31.3217H58.6781C58.2894 31.3217 58.0696 31.1702 58.0029 30.7676C57.7163 31.1543 57.2295 31.4054 56.6328 31.4054C55.8987 31.4054 55.3138 31.0387 55.3138 30.2254C55.3138 29.4122 55.9144 29.0215 56.7467 28.9458L57.9518 28.8301V28.5551C57.9518 27.9452 57.6378 27.5345 56.994 27.5345C56.3502 27.5345 56.1185 27.9133 56.0283 28.4156H55.4747C55.5572 27.6382 56.0911 27.0602 56.99 27.0602C57.889 27.0602 58.4975 27.6023 58.4975 28.5431V30.5284C58.4975 30.7795 58.6114 30.8872 58.8351 30.8872H58.9411L58.949 30.8792ZM57.9518 29.2647L56.8958 29.3643C56.2952 29.4241 55.8673 29.6992 55.8673 30.2174C55.8673 30.7357 56.2559 30.939 56.6917 30.939C57.2845 30.939 57.9518 30.6121 57.9518 29.7271V29.2647Z"
        fill="#69E3DB"
      />
      <path
        d="M59.793 27.1399H60.2798L60.3465 27.71H60.3975C60.586 27.3591 60.8843 27.0562 61.5438 27.0562H61.7598V27.6262C61.6459 27.6103 61.5556 27.6103 61.4222 27.6103C60.8137 27.6103 60.3504 28.1206 60.3504 29.0095V31.3297H59.7969V27.1439L59.793 27.1399Z"
        fill="#69E3DB"
      />
      <path
        d="M63.173 30.4805V27.6023H62.2976V27.1439H62.5763C63.0552 27.1439 63.2201 26.9924 63.2201 26.5419V26.0077H63.7226V27.1479H64.7197V27.6063H63.7226V30.4008C63.7226 30.7357 63.8718 30.935 64.2329 30.935C64.3664 30.935 64.5117 30.9111 64.6373 30.8672V31.3257C64.5156 31.3576 64.3311 31.4094 64.1034 31.4094C63.4125 31.4094 63.173 30.9908 63.173 30.4805Z"
        fill="#69E3DB"
      />
      <path
        d="M67.5737 29.2328C67.5737 27.993 68.3981 27.0562 69.5758 27.0562C70.7535 27.0562 71.5936 28.001 71.5936 29.2328C71.5936 30.4646 70.7457 31.4094 69.5758 31.4094C68.406 31.4094 67.5737 30.4566 67.5737 29.2328ZM71.044 29.2328C71.044 28.3199 70.4512 27.5585 69.5758 27.5585C68.7004 27.5585 68.1155 28.3199 68.1155 29.2328C68.1155 30.1457 68.6925 30.9071 69.5758 30.9071C70.4591 30.9071 71.044 30.1457 71.044 29.2328Z"
        fill="#69E3DB"
      />
      <path
        d="M73.4191 27.1399H74.3573V27.5983H73.4191V31.3217H72.8734V27.5983H72.0412V27.1399H72.8734V26.3267C72.8734 25.7247 73.2542 25.3819 73.8627 25.3819C74.1178 25.3819 74.2906 25.4417 74.4319 25.4975L74.3181 25.924C74.1846 25.8762 74.0472 25.8562 73.949 25.8562C73.5682 25.8562 73.423 26.0755 73.423 26.4024V27.1399H73.4191Z"
        fill="#69E3DB"
      />
      <path
        d="M82.1341 25.4656V31.3257H81.4824L78.2084 26.4223H78.1574V31.3257H77.596V25.4656H78.2555L81.5099 30.361H81.561V25.4656H82.1302H82.1341Z"
        fill="#69E3DB"
      />
      <path
        d="M83.1352 28.3956C83.1352 26.6615 84.3207 25.3819 85.9146 25.3819C87.5084 25.3819 88.6939 26.6615 88.6939 28.3956C88.6939 30.1297 87.5005 31.4094 85.9146 31.4094C84.3286 31.4094 83.1352 30.1297 83.1352 28.3956ZM88.1129 28.3956C88.1129 26.9884 87.1668 25.928 85.9106 25.928C84.6544 25.928 83.7083 26.9924 83.7083 28.3956C83.7083 29.7989 84.6544 30.8633 85.9106 30.8633C87.1668 30.8633 88.1129 29.7989 88.1129 28.3956Z"
        fill="#69E3DB"
      />
      <path
        d="M93.448 29.7351C93.448 30.7397 92.812 31.3257 91.7481 31.3257H89.746V25.4656H91.7167C92.7806 25.4656 93.3066 26.0436 93.3066 26.9206C93.3066 27.69 92.9101 28.0488 92.4822 28.2441V28.276C92.9769 28.4674 93.448 28.87 93.448 29.7311V29.7351ZM90.3074 28.0608H91.6657C92.3566 28.0608 92.7374 27.6342 92.7374 26.9804C92.7374 26.3266 92.3174 25.9679 91.6892 25.9679H90.3035V28.0608H90.3074ZM92.8709 29.7191C92.8709 29.0335 92.4076 28.5551 91.701 28.5551H90.3074V30.8234H91.7089C92.4194 30.8234 92.8709 30.4048 92.8709 29.7191Z"
        fill="#69E3DB"
      />
      <path
        d="M97.6563 29.6235H95.3166L94.7552 31.3217H94.1467L96.1331 25.4616H96.8515L98.8379 31.3217H98.2216L97.6602 29.6235H97.6563ZM97.4836 29.1132L96.51 26.1592H96.459L95.4854 29.1132H97.4796H97.4836Z"
        fill="#69E3DB"
      />
      <path
        d="M105.869 29.7351C105.869 30.7397 105.233 31.3257 104.169 31.3257H102.167V25.4656H104.138C105.201 25.4656 105.728 26.0436 105.728 26.9206C105.728 27.69 105.331 28.0488 104.903 28.2441V28.276C105.398 28.4674 105.869 28.87 105.869 29.7311V29.7351ZM102.728 28.0608H104.087C104.778 28.0608 105.158 27.6342 105.158 26.9804C105.158 26.3266 104.738 25.9679 104.11 25.9679H102.724V28.0608H102.728ZM105.292 29.7191C105.292 29.0335 104.829 28.5551 104.122 28.5551H102.728V30.8234H104.13C104.84 30.8234 105.292 30.4048 105.292 29.7191Z"
        fill="#69E3DB"
      />
      <path
        d="M110.305 30.8792V31.3217H110.034C109.645 31.3217 109.426 31.1702 109.359 30.7676C109.072 31.1543 108.585 31.4054 107.989 31.4054C107.255 31.4054 106.67 31.0387 106.67 30.2254C106.67 29.4122 107.27 29.0215 108.103 28.9458L109.308 28.8301V28.5551C109.308 27.9452 108.994 27.5345 108.35 27.5345C107.706 27.5345 107.474 27.9133 107.384 28.4156H106.831C106.913 27.6382 107.451 27.0602 108.346 27.0602C109.241 27.0602 109.853 27.6023 109.853 28.5431V30.5284C109.853 30.7795 109.967 30.8872 110.191 30.8872H110.297L110.305 30.8792ZM109.308 29.2647L108.252 29.3643C107.651 29.4241 107.223 29.6992 107.223 30.2174C107.223 30.7357 107.612 30.939 108.048 30.939C108.64 30.939 109.308 30.6121 109.308 29.7271V29.2647Z"
        fill="#69E3DB"
      />
      <path
        d="M111.149 27.1399H111.644L111.702 27.6422H111.753C111.958 27.3512 112.279 27.0562 112.939 27.0562C113.763 27.0562 114.392 27.5824 114.392 28.6388V31.3257H113.838V28.7743C113.838 27.9212 113.394 27.5625 112.833 27.5625C112.24 27.5625 111.702 27.9491 111.702 28.87V31.3297H111.149V27.1439V27.1399Z"
        fill="#69E3DB"
      />
      <path
        d="M117.167 28.8381L118.855 31.3257H118.227L116.786 29.2248L116.15 29.8627V31.3257H115.597V25.3819H116.15V29.1132H116.201L118.164 27.1359H118.855L117.167 28.8341V28.8381Z"
        fill="#69E3DB"
      />
      <path
        d="M127.099 28.3438V31.3217H126.644L126.569 30.5922H126.518C126.271 30.8672 125.694 31.4054 124.653 31.4054C123.048 31.4054 122.058 30.1816 122.058 28.4754C122.058 26.7691 123.154 25.3779 124.81 25.3779C126.039 25.3779 126.871 26.0795 127.068 27.0602H126.467C126.271 26.3984 125.694 25.92 124.81 25.92C123.499 25.92 122.635 26.9924 122.635 28.4634C122.635 29.8347 123.401 30.8672 124.696 30.8672C125.627 30.8672 126.534 30.3729 126.534 29.3006V28.8222H124.728V28.3358H127.095L127.099 28.3438Z"
        fill="#69E3DB"
      />
      <path
        d="M128.493 27.1399H128.979L129.046 27.71H129.097C129.286 27.3591 129.584 27.0562 130.243 27.0562H130.455V27.6262C130.342 27.6103 130.251 27.6103 130.118 27.6103C129.509 27.6103 129.046 28.1206 129.046 29.0095V31.3297H128.493V27.1439V27.1399Z"
        fill="#69E3DB"
      />
      <path
        d="M130.95 29.2328C130.95 27.993 131.774 27.0562 132.952 27.0562C134.13 27.0562 134.97 28.001 134.97 29.2328C134.97 30.4646 134.122 31.4094 132.952 31.4094C131.782 31.4094 130.95 30.4566 130.95 29.2328ZM134.42 29.2328C134.42 28.3199 133.828 27.5585 132.952 27.5585C132.077 27.5585 131.492 28.3199 131.492 29.2328C131.492 30.1457 132.069 30.9071 132.952 30.9071C133.835 30.9071 134.42 30.1457 134.42 29.2328Z"
        fill="#69E3DB"
      />
      <path
        d="M139.21 31.3257H138.715L138.656 30.8234H138.609C138.401 31.1144 138.083 31.4094 137.424 31.4094C136.599 31.4094 135.971 30.8832 135.971 29.8268V27.1399H136.525V29.6912C136.525 30.5443 136.968 30.9031 137.53 30.9031C138.122 30.9031 138.66 30.5164 138.66 29.5956V27.1359H139.214V31.3217L139.21 31.3257Z"
        fill="#69E3DB"
      />
      <path
        d="M144.231 29.2408C144.231 30.5643 143.446 31.4094 142.358 31.4094C141.6 31.4094 141.173 30.9988 140.98 30.6799V33H140.427V27.1399H140.89L140.937 27.7857H140.988C141.184 27.4827 141.597 27.0562 142.354 27.0562C143.457 27.0562 144.227 27.9332 144.227 29.2408H144.231ZM143.685 29.2408C143.685 28.2282 143.116 27.5585 142.307 27.5585C141.498 27.5585 140.98 28.2282 140.98 29.2408C140.98 30.2533 141.498 30.9071 142.307 30.9071C143.116 30.9071 143.685 30.2374 143.685 29.2408Z"
        fill="#69E3DB"
      />
    </g>
    <defs>
      <clipPath id="clip0_10_10491">
        <rect width="171" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
