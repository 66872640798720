import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import { MicropaymentInfoContent } from '../../styles/generic.styles'
import { MicropaymentInfoGraph } from '../../styles/ikano.styles'
import { IkanoOpenbankingInfoGraph } from './IkanoOpenbankingInfoGraph'
import {
  TermsAndConditions,
  AdditionalParagraphProps,
} from '../../components/TermsAndConditions/TermsAndConditions'

const AdditionalParagraph: React.FC<AdditionalParagraphProps> = ({ trans }) => (
  <>
    <MicropaymentInfoGraph>
      <IkanoOpenbankingInfoGraph />
    </MicropaymentInfoGraph>

    {trans.content.split('\n').map((item, i) => (
      <MicropaymentInfoContent key={i}>{item}</MicropaymentInfoContent>
    ))}
  </>
)

export const TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage = observer(
  () => {
    const store = useContext(StoreContext)
    const { micropaymentInfoIkanoOpenBankingPage: trans } =
      store.TranslationsState.translations

    return (
      <TermsAndConditions
        showHeader={false}
        showTitle={false}
        buttonText={trans.proceed}
        AdditionalParagraph={AdditionalParagraph}
        additionalParagraphProps={{ trans: trans }}
      />
    )
  }
)
