import {
  Button,
  ContentContainer,
  CountrySelect,
  Header,
  Icon,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../components/App'
import {
  ButtonImgWrap,
  ButtonText,
  SelectHolder,
} from '../styles/generic.styles'
import {
  ContentContainerBottom,
  ContentContainerBottomLeft,
} from '../styles/layout.styles'

export const CountryPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState
  const { generalConditionsPage: trans } = store.TranslationsState.translations

  const [country, setCountry] = useState(null)

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  const handleCountry = (country) => {
    if (country) {
      setCountry(country)
      store.AppState.setSelectedCountry(country?.value)
    } else {
      setCountry(null)
    }
  }

  return (
    <ContentContainer {...theme.container} width="560px">
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        Select your country
      </Header>
      <SelectHolder>
        <CountrySelect
          value={country}
          onChange={(value) => handleCountry(value)}
          {...theme.selectInput}
          showCheckmark={theme.wcag}
        />
      </SelectHolder>
      <ContentContainerBottom>
        <ContentContainerBottomLeft />
        <Button
          onClick={() => handleProceed()}
          disabled={!country}
          {...theme.button}
        >
          <ButtonImgWrap $hide={theme.wcag}>
            <Icon size="18px" type="checkmark" />
          </ButtonImgWrap>
          <ButtonText>{trans.proceed}</ButtonText>
        </Button>
      </ContentContainerBottom>
    </ContentContainer>
  )
})
