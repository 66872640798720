import styled, { css } from 'styled-components'

interface ContainerInterface {
  borderWidth?: string
  color?: string
  colorDisabled?: string
  borderColor?: string
  borderRadius?: string
  backgroundColor?: string
  borderColorFocus?: string
  colorFocus?: string
  backgroundColorFocus?: string
  colorSelected?: string
  backgroundColorSelected?: string
  menuMarginTop?: string
  menuBorderWidth?: string
  indicatorColorFocus?: string
  indicatorColorHover?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-select-input',
})<ContainerInterface>`
  display: inline-block;
  width: 100%;

  .select-input {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    &__value-container {
      padding: 10px 14px;
    }

    &__single-value {
      color: ${(props) => (props.color ? props.color : 'hsl(0, 0%, 20%)')};
      margin: 0;

      &--is-disabled {
        color: ${(props) =>
          props.colorDisabled ? props.colorDisabled : 'pink'};
      }
    }

    &__input-container {
      color: ${(props) => (props.color ? props.color : '#000')};
      margin: 0;
    }

    &__input {
      caret-color: transparent;
    }

    &__control {
      border-width: ${(props) =>
        props.borderWidth ? props.borderWidth : '100%'};
      background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : '#fff'};
      color: ${(props) => (props.color ? props.color : '#000')};
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : '8px'};
      min-width: 160px;
      border-color: ${(props) =>
        props.borderColor ? props.borderColor : 'hsl(0, 0%, 80%)'}!important;

      :not(&--is-focused) {
        box-shadow: none;
      }

      .select-input__indicator {
        color: ${(props) =>
          props.borderColor ? props.borderColor : 'hsl(0, 0%, 80%)'};

        :hover {
          color: ${(props) =>
            props.indicatorColorHover
              ? props.indicatorColorHover
              : 'hsl(0, 0%, 80%)'};
        }
      }

      .select-input__indicator-separator {
        background-color: ${(props) =>
          props.borderColor ? props.borderColor : 'hsl(0, 0%, 80%)'}!important;
      }

      :hover {
        cursor: pointer;
        border-color: ${(props) =>
          props.borderColorFocus ? props.borderColorFocus : '#000'}!important;
        box-shadow: 0px 0px 0px 1px
          ${(props) =>
            props.borderColorFocus ? props.borderColorFocus : '#000'};
      }

      &--is-focused {
        border-color: ${(props) =>
          props.borderColorFocus ? props.borderColorFocus : '#000'};
        outline: ${({ theme }) =>
          theme.globals.focusVisible.outline} !important;
        box-shadow: ${({ theme }) =>
          theme.globals.focusVisible.boxShadow} !important;
        transition: ${({ theme }) => theme.globals.focusVisible.transition};

        .select-input__indicator {
          color: ${(props) =>
            props.indicatorColorFocus
              ? props.indicatorColorFocus
              : 'hsl(0, 0%, 80%)'};
        }

        .select-input__indicator-separator {
          background-color: ${(props) =>
            props.borderColorFocus
              ? props.borderColorFocus
              : 'hsl(0, 0%, 80%)'}!important;
        }
      }

      &--menu-is-open {
        background-color: ${(props) =>
          props.backgroundColor ? props.backgroundColor : '#fff'};
        border-radius: ${(props) =>
            props.borderRadius
              ? `${props.borderRadius} ${props.borderRadius}`
              : '8px 8px'}
          0px 0px;
        border-color: ${(props) =>
          props.borderColorFocus ? props.borderColorFocus : '#000'}!important;

        :hover {
          background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#fff'};
          border-color: ${(props) =>
            props.borderColorFocus ? props.borderColorFocus : '#000'};
        }
      }
    }

    &__menu {
      border-radius: 0px 0px
        ${(props) =>
          props.borderRadius
            ? `${props.borderRadius} ${props.borderRadius}`
            : '8px 8px'};
      border-color: ${(props) =>
        props.borderColorFocus ? props.borderColorFocus : '#000'};
      background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : '#FFF'};

      ${({ menuMarginTop }) =>
        menuMarginTop &&
        css`
          margin-top: ${menuMarginTop};
        `}

      ${({ menuBorderWidth }) =>
        menuBorderWidth &&
        css`
          border-width: ${menuBorderWidth};
          border-style: solid;
          box-shadow: none;
        `}
    }

    &__menu-list {
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px 0px
        ${(props) =>
          props.borderRadius
            ? `${props.borderRadius} ${props.borderRadius}`
            : '8px 8px'};
    }

    &__option {
      padding: 10px 16px;
      color: ${(props) => (props.color ? props.color : '#000')};
      cursor: pointer;

      & span {
        margin-bottom: 0;
      }

      &--is-focused,
      &:focus-visible,
      &:active {
        background-color: ${(props) =>
          props.backgroundColorFocus
            ? props.backgroundColorFocus
            : 'transparent'};
      }

      &--is-selected {
        background-color: ${(props) =>
          props.backgroundColorSelected
            ? props.backgroundColorSelected
            : '#000'} !important;

        ${({ showCheckmark }) =>
          showCheckmark &&
          css`
            background-image: ${(props) =>
              `url('data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20viewBox%3D%220%200%2012%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M11.3692%200.466489C11.6638%200.780755%2011.6479%201.27437%2011.3337%201.569L4.93388%207.569C4.63386%207.85029%204.16698%207.8503%203.86694%207.56903L0.666715%204.56903C0.352433%204.27441%200.336492%203.7808%200.63111%203.46652C0.925729%203.15224%201.41934%203.1363%201.73362%203.43092L4.40037%205.93081L10.2667%200.430944C10.581%200.136309%2011.0746%200.152223%2011.3692%200.466489Z%22%20fill%3D%22${encodeURIComponent(
                props.colorSelected ? props.colorSelected : '#FFF'
              )}%22%2F%3E%3C%2Fsvg%3E')`};
            background-position: calc(100% - 20px) center;
            background-repeat: no-repeat;
          `}

        &:hover {
          background-position: calc(100% - px) center;
        }

        span {
          color: ${(props) =>
            props.colorSelected ? props.colorSelected : '#FFF'} !important;
          font-weight: 700;
        }
      }
    }

    &__indicator-separator {
      width: ${(props) => (props.borderWidth ? props.borderWidth : '100%')};
    }
  }
`
