import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
} from 'react-select'
import { SelectInput, SelectOption } from '../SelectInput/SelectInput'
import { countriesList } from './countriesList'
import { externalList } from './countriesListExternal'

import {
  Container,
  CountryFlag,
  CountryItemInner,
} from './CountrySelect.styles'

const { Option, SingleValue } = components

interface CountrySelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  value: null | SelectOption
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  className?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void
  borderRadius?: string
  borderWidth?: string
  color?: string
  colorDisabled?: string
  backgroundColor?: string
  borderColorFocus?: string
  colorFocus?: string
  backgroundColorFocus?: string
  colorSelected?: string
  backgroundColorSelected?: string
  menuMarginTop?: string
  menuBorderWidth?: string
  indicatorColorFocus?: string
  indicatorColorHover?: string
  danger?: string
  dangerLight?: string
  autoFocus?: boolean
  defaultMenuIsOpen?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalList?: any[]
  placeholder?: string
  borderColor?: string
  showCheckmark?: boolean
}

function getExternalFlag(countryCode: string) {
  const country = countriesList.find((country) => country.value == countryCode)
  return country?.icon || ''
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountrySingleValue = (props: any) => (
  <SingleValue {...props}>
    <CountryItemInner>
      {externalList
        ? props.data.icon && (
            <CountryFlag>
              <LazyLoadImage
                alt={props.data.value}
                src={getExternalFlag(props.data.value)}
              />
            </CountryFlag>
          )
        : props.data.icon && (
            <CountryFlag>
              <LazyLoadImage alt={props.data.value} src={props.data.icon} />
            </CountryFlag>
          )}
      <span>{props.data.label}</span>
    </CountryItemInner>
  </SingleValue>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountryOption = (props: any) => (
  <Option {...props}>
    <CountryItemInner>
      {externalList
        ? props.data.icon && (
            <CountryFlag>
              <LazyLoadImage
                alt={props.data.value}
                src={getExternalFlag(props.data.value)}
              />
            </CountryFlag>
          )
        : props.data.icon && (
            <CountryFlag>
              <LazyLoadImage alt={props.data.value} src={props.data.icon} />
            </CountryFlag>
          )}
      <span>{props.data.label}</span>
    </CountryItemInner>
  </Option>
)

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.96 9.04036C18.2139 9.2942 18.2139 9.70575 17.96 9.95959L12.96 14.9596C12.7062 15.2134 12.2946 15.2134 12.0408 14.9596L7.04077 9.9596C6.78693 9.70575 6.78693 9.2942 7.04077 9.04036C7.29461 8.78652 7.70617 8.78652 7.96001 9.04036L12.5004 13.5807L17.0408 9.04036C17.2946 8.78652 17.7062 8.78652 17.96 9.04036Z"
          fill="black"
        />
      </svg>
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.54048 6.54036C6.79432 6.28652 7.20588 6.28652 7.45972 6.54036L12.0001 11.0807L16.5405 6.54036C16.7943 6.28652 17.2059 6.28652 17.4597 6.54036C17.7136 6.7942 17.7136 7.20575 17.4597 7.45959L12.9193 12L17.4597 16.5404C17.7136 16.7942 17.7136 17.2058 17.4597 17.4596C17.2059 17.7134 16.7943 17.7134 16.5405 17.4596L12.0001 12.9192L7.45972 17.4596C7.20588 17.7134 6.79432 17.7134 6.54048 17.4596C6.28664 17.2058 6.28664 16.7942 6.54048 16.5404L11.0809 12L6.54048 7.45959C6.28664 7.20575 6.28664 6.7942 6.54048 6.54036Z"
          fill="black"
        />
      </svg>
    </components.ClearIndicator>
  )
}

export const CountrySelect = (props: CountrySelectProps) => {
  const [selectValue, setSelectValue] = useState(null)

  function handleChange(value) {
    setSelectValue(value)
    props.onChange(value)
  }

  useEffect(() => setSelectValue(props.value), [props.value])

  return (
    <Container>
      <SelectInput
        options={props.externalList ? props.externalList : countriesList}
        value={selectValue}
        onChange={handleChange}
        isClearable={true}
        components={{
          SingleValue: CountrySingleValue,
          Option: CountryOption,
          DropdownIndicator,
          ClearIndicator,
        }}
        className={props.className ? props.className : ''}
        borderRadius={props.borderRadius ? props.borderRadius : '0'}
        borderWidth={props.borderWidth}
        color={props.color}
        colorDisabled={props.colorDisabled}
        borderColor={props.borderColor}
        backgroundColor={props.backgroundColor}
        borderColorFocus={props.borderColorFocus}
        colorFocus={props.colorFocus}
        backgroundColorFocus={props.backgroundColorFocus}
        colorSelected={props.colorSelected}
        backgroundColorSelected={props.backgroundColorSelected}
        menuMarginTop={props.menuMarginTop}
        menuBorderWidth={props.menuBorderWidth}
        indicatorColorFocus={props.indicatorColorFocus}
        indicatorColorHover={props.indicatorColorHover}
        danger={props.danger ? props.danger : 'red'}
        dangerLight={props.dangerLight ? props.dangerLight : 'purple'}
        autoFocus={props.autoFocus ? props.autoFocus : false}
        defaultMenuIsOpen={
          props.defaultMenuIsOpen ? props.defaultMenuIsOpen : false
        }
        placeholder={
          props.placeholder ? props.placeholder : 'Select your country'
        }
        showCheckmark={props.showCheckmark}
      />
    </Container>
  )
}
