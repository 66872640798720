import React, { useEffect, useRef, useState } from 'react'

import { Icon } from '../Icon/Icon'
import { Condition, Content, GoTo, MainLabel } from './DocumentCheckbox.styles'
import { onKeyDownHandler } from '@/methods/keyboardAccessibility'

interface DocumentCheckboxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  inputName: string
  description: string
  externalUrl: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDecision: (event: any) => void
  value: boolean
  iconColor?: string
  iconHover?: string
  width?: string
  contentFontsize?: string
  margin?: string
  marginMobile?: string
  iconSize?: string
  onClick?: () => void
  tabIndex?: number
}

export const DocumentCheckbox = ({
  inputName,
  description,
  externalUrl,
  handleDecision,
  value,
  iconColor,
  iconHover,
  width,
  contentFontsize,
  margin,
  marginMobile,
  iconSize,
  onClick,
  tabIndex,
}: DocumentCheckboxProps) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const onChangeHandler = () => {
    setInputValue((inputValue) => !inputValue)
  }

  useEffect(() => {
    handleDecision({
      checked: inputValue,
      id: inputName,
    })
  }, [inputValue])

  return (
    <Condition width={width}>
      <Content
        margin={margin}
        marginMobile={marginMobile}
        contentFontsize={contentFontsize}
      >
        <MainLabel htmlFor={inputName} data-testid={`${inputName}_label`}>
          <input
            id={inputName}
            type="checkbox"
            name={inputName}
            onChange={onChangeHandler}
            checked={inputValue}
            data-testid={`${inputName}_input`}
          />
          <span
            className="label-text"
            tabIndex={tabIndex}
            onKeyDown={(event) =>
              onKeyDownHandler(event, () => onChangeHandler())
            }
          >
            {description}
          </span>
        </MainLabel>
        {onClick ? (
          <GoTo
            onClick={onClick}
            data-testid={`${inputName}_link`}
            tabIndex={tabIndex}
            role="button"
            onKeyDown={(event: KeyboardEvent) =>
              onKeyDownHandler(event, () => onClick())
            }
          >
            <Icon
              type="external-link"
              size={iconSize ? iconSize : '18px'}
              color={iconColor}
              colorHover={iconHover}
            />
          </GoTo>
        ) : (
          <GoTo
            target="_blank"
            href={externalUrl}
            data-testid={`${inputName}_link`}
            tabIndex={tabIndex}
            role="button"
          >
            <Icon
              type="external-link"
              size={iconSize ? iconSize : '18px'}
              color={iconColor}
              colorHover={iconHover}
            />
          </GoTo>
        )}
      </Content>
    </Condition>
  )
}
